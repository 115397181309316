<template>
  <div id="signIn">
    <van-nav-bar title="待上门订单" left-arrow @click-left="goLastPage" :border="false" />
    
 
    <div class="signInWrap">
      <span class="tip" v-if="signInList.length == 0? true: false">你还没有待上门订单哦</span>
      <span class="tip" v-else>师傅继续努力吧</span>
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <OrderListPublic v-for="(item,i) in signInList" :key="i" :listPublicObj="item" :toPath="`/signInDetails/${item.id}`" toPageName="sign-in-detail" :toPageId="item.id" />
      </van-pull-refresh>
    </div>
  </div>
</template>
<script>
import TopBar from "@/components/home/TopBar.vue";
import OrderListPublic from "@/components/order/OrderListPublic.vue";

export default {
  data() {
    return {
      signInList: [],
      isLoading: true,
    };
  },
  components: {
    TopBar,
    OrderListPublic,
  },
  created() {
    this.getSignInList();
  },
  methods: {
    getSignInList() {
      this.$http
        .get("/api/v2/worker/orders/service?work_status=3.7.8.9")
        .then((res) => {
          console.log("待上门订单列表", res);
          this.signInList = res.data.result.data;
          this.isLoading = false;
        });
    },
    onRefresh() {
      this.getSignInList();
    },
  },
};
</script>
<style lang="less" scoped>
#signIn {
  .tip {
    margin-top: 45px;
    font-size: 1.4rem;
    padding: 1rem 0;
    text-align: center;
    margin: 1rem 1rem 0 1rem;
    display: block;
    box-sizing: border-box;
    border-radius: 5px;
  }
}
</style>